import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import MenuLeft from "../components/Header/MenuLeft";
import MenuScan from "../components/Header/MenuScan";
import Dashboard from "../pages/Dashboard";
import Hinting from "../pages/Hinting";
// import TextLeft from '../components/TextLeft';
import AddressManager from "../pages/AddressManager";
import ChatSupport from "../pages/ChatSupport";
import CreditCardManager from "../pages/CreditCardManager";
import MachineBasket from "../pages/MachineBasket";
import OfferBasket from "../pages/OfferBasket";
import Payment from "../pages/Payment";
import PaymentConfirmed from "../pages/PaymentConfirmed";
import Pix from "../pages/Pix";
import PixError from "../pages/PixError";
import Scan from "../pages/Scan";
import SearchCategory from "../pages/SearchCategory";
import { ActionColor } from "../utils/colors";
import { normalizeLimit } from "../utils/theme";

const Stack = createStackNavigator();

const HomeRoutes: React.FC = () => (
  <Stack.Navigator
    initialRouteName="Home"
    screenOptions={{ headerMode: "float", headerShown: false }}
  >
    <Stack.Screen
      name="Home"
      component={Dashboard}
      options={{
        headerTitleStyle: {
          fontWeight: "bold",
          fontSize: normalizeLimit(16),
          color: ActionColor,
        },
        title: "Snackly - Conveniência Inteligente",
        headerShown: true,
        headerRight: () => <MenuScan />,
      }}
    />
    <Stack.Screen
      name="MachineBasket"
      component={MachineBasket}
      options={{
        headerTitleStyle: { fontSize: normalizeLimit(15) },
        title: "Seus produtos estarão em:",
        headerLeft: (props) => <MenuLeft />,
      }}
    />
    <Stack.Screen
      name="Payment"
      component={Payment}
      options={{
        headerTitleStyle: { fontSize: normalizeLimit(15) },
        title: "Seus produtos estarão em:",
        headerLeft: (props) => <MenuLeft />,
      }}
    />

    <Stack.Screen
      name="Pix"
      component={Pix}
      options={{
        headerTitleStyle: { fontSize: normalizeLimit(15) },
        title: "Pagamento Pix",
        headerLeft: (props) => <MenuLeft />,
      }}
    />
    <Stack.Screen
      name="PixError"
      component={PixError}
      options={{ headerShown: false }}
    />

    <Stack.Screen
      name="PaymentConfirmed"
      component={PaymentConfirmed}
      options={{ headerShown: false }}
    />

    <Stack.Screen
      name="ChatSupport"
      component={ChatSupport}
      options={{
        headerTitleStyle: { display: "flex" },
        title: "Chat",
        headerShown: false,
        headerLeft: (props) => <MenuLeft />,
      }}
    />

    <Stack.Screen
      name="OfferBasket"
      component={OfferBasket}
      options={{
        headerBackTitleVisible: false,
        title: "Detalhes da oferta",
        headerTitleAlign: "center",
        headerStyle: {
          shadowRadius: 0,
          shadowOffset: { height: 0, width: 0 },
        },
        headerLeft: (props) => <MenuLeft />,
      }}
    />
    <Stack.Screen
      name="SearchCategory"
      component={SearchCategory}
      options={{
        title: "Pesquisa de Produtos",
        headerLeft: (props) => <MenuLeft />,
      }}
    />
    <Stack.Screen name="Hinting" component={Hinting} />
    <Stack.Screen
      name="Scan"
      component={Scan}
      options={{
        headerTitleStyle: { display: "flex" },
        title: "Escanear Qrcode",
        headerShown: true,
        headerLeft: (props) => <MenuLeft />,
      }}
    />
    <Stack.Screen
      name="CreditCardManager"
      component={CreditCardManager}
      options={{
        headerTitleStyle: { display: "flex" },
        title: "Cartão de Crédito",
        headerShown: true,
        headerLeft: (props) => <MenuLeft />,
      }}
    />
    <Stack.Screen
      name="AddressManager"
      component={AddressManager}
      options={{
        headerTitleStyle: { display: "flex" },
        title: "Endereços",
        headerShown: true,
        headerLeft: (props) => <MenuLeft />,
      }}
    />
  </Stack.Navigator>
);

export default HomeRoutes;
