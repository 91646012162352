import { AxiosResponse } from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { ResultLogin, User } from "../models/user";
import api from "../services/api";
import * as auth from "../services/auth";
import userService from "../services/userservice";
import { UserSupport } from "../models/userSupport";

interface AuthContextData {
  machineId: string;
  isAuthScan: boolean;
  signed: boolean;
  user: User;
  loading: boolean;
  userSupport: UserSupport;
  setUserSupportData(data: UserSupport): void;
  signIn(email: string, password: string): Promise<AxiosResponse<ResultLogin>>;
  signOut(): void;
  setContextUser(user: User): void;
  setAuthScan(isScan: boolean): void;
  setScanMachineId(id: string): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [userSupport, setUserSupport] = useState<UserSupport>(
    {} as UserSupport
  );
  const [machineId, setMachineId] = useState("");
  const [isAuthScan, setIsAuthScan] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStoregedData = async () => {
      const storegedUser = await userService.getLogedUser();
      const storegedToken = await userService.getLogedToken();

      //FIXME:retirar apenas pa testar loading
      //await new Promise((resolve) => setTimeout(resolve, 1000));

      if (storegedUser && storegedToken) {
        api.defaults.headers["Authorization"] = `${storegedToken}`;
        setUser(storegedUser);
        setLoading(false);
      } else if (!storegedUser) {
        setLoading(false);
      }
    };
    loadStoregedData();
  }, []);

  function setAuthScan(isScan: boolean) {
    setIsAuthScan(isScan);
  }

  function setScanMachineId(id: string) {
    setMachineId(id);
  }
  async function signIn(email: string, password: string) {
    const response = await auth.signIn(email, password);

    if (response) {
      const { user, idToken, accessToken, refreshToken } = response.data;

      if (user && idToken) {
        //Ordena pedidos pela data
        // user.puchases = userData.purchases.sort((a,b) => (a.date && b.date && a.date > b.date) ? -1 : (a.date && b.date && a.date < b.date ? 1 : 0));
        setUser(user);
        api.defaults.headers["Authorization"] = `${accessToken}`;
        await userService.setLogedUser(user);
        await userService.setLogedToken(accessToken);

        if (refreshToken) {
          await userService.setRefreshToken(refreshToken);
        }
      }

      return response;
    }
  }

  async function signOut() {
    setIsAuthScan(false);
    //window.location.pathname = "";
    await userService.clear();
    setUser(null);
  }

  async function setContextUser(user: User) {
    setUser(user);
    await userService.setLogedUser(user);
  }

  function setUserSupportData(data: UserSupport) {
    setUserSupport(data);
  }

  return (
    <AuthContext.Provider
      value={{
        machineId,
        isAuthScan,
        signed: !!(user && user?.status >= 2),
        user,
        loading,
        userSupport,
        setUserSupportData,
        signIn,
        signOut,
        setContextUser,
        setAuthScan,
        setScanMachineId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
