import {normalizeLimit} from '../../utils/theme';
import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';

export const Container = styled.View`
  margin: 0px 0px 0px 0px;
  background: #FFFFFF;
  padding: 5px 15px 0px 15px;
  flex: 1;
`;

export const ResultContainer = styled.View`
  margin: 0px 0px 0px 0px;
`;


export const ProductList = styled.ScrollView`
  margin-top: 10px;
`;

export const ProductItem = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 0px;
`;

export const ItemImage = styled.Image`
  width: ${normalizeLimit(60)}px;
  height: ${normalizeLimit(60)}px;
  border-radius: ${normalizeLimit(30)}px;
`;

export const ItemInfo = styled.View`
  margin-left: 15px;
  flex: 1;
`;

export const ItemHeaderInfor = styled.View`
  flex: 1;
  color: #333;
`;

export const ItemTitle = styled.Text`
  font-weight: bold;
  font-size: ${normalizeLimit(15)}px;
  color: #333;
  margin-bottom: 6px;
`;

export const ItemAvailability = styled.Text`
  position: absolute;
  top: 0;
  right: -8px;
  z-index: 5;
  font-size: ${normalizeLimit(12)}px;
  margin-left: 6px;
  background: ${ActionColor};
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: #fff;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
`;

export const ItemTag = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  align-items: center;
  position: relative;
`;

export const ItemQuantify = styled.View`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 3px;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0 0px 10px;
`;

export const Categories = styled.Text`
  font-size: 14px;
  color: #999;
`;

export const Price = styled.Text`
  font-weight: bold;
  font-size: 14px;
  margin-right: 18px;
  color: #333;
`;

export const TextInfo = styled.Text`
  font-size: 14px;
  color: #999;
`;

//Machines
export const MachineSelected = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 0px;
`;

export const Star = styled.Text`
  font-size: 14px;
  color: #FF7C01;
`;

export const Distance = styled.Text`
  font-size: 14px;
  color: #999;
`;

export const Restriction = styled.Text`
  margin-top: 15px;
  font-size: 14px;
  color: #999;
`;

export const ConfirmButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 10px 10px 10px 10px;
  border: 1px solid ${ActionColor};
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 0px;
`;

export const InfoPurchase = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 30px 0px;
`;

export const ProductsAvaliable = styled.Text`
  font-weight: bold;
  color: #333;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 0px 10px;
  margin-top: 0px;
  margin-right: 0px;
`;

export const PurchaseTotal = styled.Text`
  flex: 1;
  font-weight: bold;
  color: #333;
  margin-top: 0px;
  margin-left: 0px;
  padding: 10px 10px 0px 0px;
  align-items: flex-end;
  text-align: right;
  line-height: 14px;
`;