import { MaterialIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Input } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";

import {
  CognitoIdentityProviderClient,
  ConfirmSignUpCommand,
  ResendConfirmationCodeCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import Link from "../../components/Link";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { ActionColor } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import { Container, LoginInfo, Logo, LogoContainer } from "./styles";
import { APPLOGO } from "../../image";

type RouteParamList = {
  username: string;
};

interface FormValues {
  code: string;
}

const SignUpVerifyValidationSchema = Yup.object().shape({
  code: Yup.number()
    .min(6, "insira um codigo válido - mínimo 6 caracteres")
    .required("insira um codigo de verificação"),
});

async function resendConfirmationCode(username: string) {
  const client = new CognitoIdentityProviderClient({
    region: "us-east-1",
  });

  const CLIENT_ID = "15cgfpn93vpns37hs5f6jspu4c";
  console.log("CLIENT_ID", CLIENT_ID);

  const command = new ResendConfirmationCodeCommand({
    ClientId: CLIENT_ID,
    Username: username,
  });

  const response = await client.send(command);

  return response;
}

async function confirmSignUp(user) {
  const client = new CognitoIdentityProviderClient({
    region: "us-east-1",
  });

  const CLIENT_ID = "15cgfpn93vpns37hs5f6jspu4c";
  console.log(CLIENT_ID);

  const command = new ConfirmSignUpCommand({
    Username: user.username,
    ConfirmationCode: user.code,
    ClientId: CLIENT_ID,
  });

  const response = await client.send(command);
  return response;
}

const SignUpVerify: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const [msg, setMessage] = useState("Cadastro realizado com sucesso!");
  const { user, setContextUser } = useAuth();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const inputCodeRef = useRef<typeof Input>(null);
  const initialValues: FormValues = {
    code: "",
  };

  useEffect(() => {
    AlertOS({
      title: "Código de Verificação",
      message: "Verifique o código que enviamos para o seu email",
      toastType: "info",
    });
  }, []);

  async function handleSignUpVerifyRegister(
    formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) {
    try {
      setLoading(true);
      const username = route.params.username;
      const code = formValues.code;
      const result = await confirmSignUp({ username, code });

      // if(result.$metadata.httpStatusCode == 200) {
      //   // setContextUser({id: user?.id, name: user?.name, email: user?.email, status: 2} as User)
      //   //   //FIXME: para evitar do novo usuário precisar logar seria necessário efetuar o login automaticamente nesse ponto.
      //   //   console.log(newMessage, result.data);
      //   // }
      // }

      if (result.$metadata.httpStatusCode == 200) {
        // setContextUser({id:result.data.username,name:formValues.name,email:formValues.email,status:1} as User);
        AlertOS({
          title: "Cadastro realizado",
          message: "Registro efetuado com sucesso!",
        });
      }

      setLoading(false);
      //TODO: logar automaticamente
      formikHelpers.resetForm();
      navigation.navigate("SignIn", { msg });
    } catch (err: any) {
      setLoading(false);
      console.log("Algo deu errado, tente novamente!", err);
      let title, message;
      switch (err.name) {
        case "CodeMismatchException":
          title = "Código de Verificação";
          message =
            "Código de verificação não corresponde ao enviado para o email";
          break;
        case "LimitExceededException":
        case "TooManyRequestsException":
        case "TooManyFailedAttemptsException":
          title = "Falha exceção de tentativas";
          message = "Excesso de tentativas";
          break;
        default:
          title = "Contate o suporte";
          message =
            "Desculpe o transtorno. Entre em contato com o nosso suporte para mais informações.";
          break;
      }
      AlertOS({
        title,
        message,
        toastType: "error",
      });
    }
  }

  async function handleSignUpVerifyResend() {
    try {
      setLoading(true);
      const { username } = route.params;
      const response = await resendConfirmationCode(username);

      if (response.$metadata.httpStatusCode) {
        AlertOS({
          title: "Verifique seu email para prosseguir.",
          message: "Código de verificação reenviado com sucesso!",
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log("Algo deu errado, tente novamente!", err);
      let title, message;
      switch (err.name) {
        default:
          title = "Contate o suporte";
          message =
            "Desculpe o transtorno. Entre em contato com o nosso suporte para mais informações.";
          break;
      }
      AlertOS({
        title,
        message,
        toastType: "error",
      });
    }
  }

  return (
    <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
      <Container>
        <LogoContainer>
          <Logo source={APPLOGO} />
        </LogoContainer>        
        <Formik
          initialValues={initialValues}
          validationSchema={SignUpVerifyValidationSchema}
          validateOnChange={false}
          onSubmit={(values, formikHelpers) =>
            handleSignUpVerifyRegister(values, formikHelpers)
          }
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <LoginInfo>
              <Input
                leftIcon={
                  <MaterialIcons name="lock" size={24} color={ActionColor} />
                }
                leftIconContainerStyle={{
                  marginLeft: 0,
                  marginRight: 10,
                }}
                label="Código de verificação"
                keyboardType="numeric"
                maxLength={6}
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                ref={inputCodeRef}
                blurOnSubmit={false}
                onSubmitEditing={() => handleSubmit()}
                errorMessage={errors.code}
                value={values.code}
                onChangeText={handleChange("code")}
                onBlur={handleBlur("code")}
                containerStyle={{
                  paddingBottom: dinamicPadding(errors.code, touched.code),
                }}
              />

              <Button
                title="Verificar"
                loading={loading}
                onPress={() => handleSubmit()}
              />

              <Button
                title="Voltar"
                loading={loading}
                onPress={() => navigation.navigate("SignUp")}
                type="outline"
                containerStyle={{
                  marginVertical: 1,
                }}
              />

              <Link
                text="Reenviar código"
                onPress={() => handleSignUpVerifyResend()}
                containerStyle={{
                  flexDirection: "row",
                }}
              />
            </LoginInfo>
          )}
        </Formik>
      </Container>
    </KeyboardAwareScrollView>
  );
};

export default SignUpVerify;
