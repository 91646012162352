import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Formik, FormikHelpers } from "formik";
import React, { useRef, useState } from "react";
import { Button, Input } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";

import { CognitoIdentityProviderClient, ForgotPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
import { COGNITO_CLIENT_ID } from '@env';
import Link from "../../components/Link";
import { APPLOGO } from "../../image";
import { ActionColor } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import { AppMessage, Container, LoginInfo, Logo, LogoContainer, Message, Title } from "./styles";
import { AlertOS } from "../../hooks/AlertOS";


interface FormValues {
  email: string;
}

const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email("insira um email válido").required("insira um email"),
});

async function ForgotPasswordCognito(user) {
  const client = new CognitoIdentityProviderClient({
    region: "us-east-1"
  });

  const CLIENT_ID = '15cgfpn93vpns37hs5f6jspu4c'
  console.log("CLIENT_ID", CLIENT_ID);

  const command = new ForgotPasswordCommand({
    Username: user.email,
    ClientId: CLIENT_ID
  })

  const response = await client.send(command);

  return {statusCode: response.$metadata.httpStatusCode, data: response}
}

const ForgotPassword: React.FC = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const inputEmailRef = useRef<typeof Input>(null);
  const initialValues: FormValues = {
    email: ""
  };

  async function handleForgotPasswordRegister(formValues: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      setLoading(true);
      
      const response = await ForgotPasswordCognito(formValues)
      const username = formValues.email
      const email = response.data.CodeDeliveryDetails.Destination
      
      if (response.statusCode == 200) {
        AlertOS({
          title: 'Verifique seu email',
          message: 'Para prosseguir, verifique o código que enviamos para o seu email.',
          toastType: 'info'
        })
      }
      setLoading(false);
      formikHelpers.resetForm();
      navigation.navigate("ForgotPasswordVerify",{username, email});
    } catch (err: any) {
      setLoading(false);
      let title, message;
      switch (err.name) {
        case 'InvalidParameterException':
        case 'UserNotFoundException':
          title = "Email Inválido"
          message = 'E-mail não cadastrado'
          break;
        case 'LimitExceededException':
          title = "Limite Excedido"
          message = 'Limite de tentativas excedido. Tenta novamente mais tarde'
          break;
        default:
          title = 'Contate o suporte'
          message = 'Desculpe o transtorno. Entre em contato com o nosso suporte para mais informações.'
          break;
      }
      AlertOS({
        title,
        message,
        toastType: 'error'
      })
    }
  }

  function handleForgotPasswordCancel() {
    navigation.navigate("SignIn");
  }

  return (
      <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
        <Container>
          <LogoContainer>
            <Logo
                source={APPLOGO}
              />
          </LogoContainer>
          <AppMessage>
            <Title>Esqueceu a senha?</Title>
            <Message>Não se preocupe! Nós iremos lhe ajudar a recuperar a sua senha.</Message>
          </AppMessage>
          <Formik
            initialValues={initialValues}
            validationSchema={ForgotPasswordValidationSchema}
            validateOnChange={false}
            onSubmit={(values, formikHelpers) =>
              handleForgotPasswordRegister(values, formikHelpers)
            }
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <LoginInfo>
                <Input
                  leftIcon={
                    <MaterialIcons name="email" size={24} color={ActionColor} />
                  }
                  leftIconContainerStyle={{
                    marginLeft: 0,
                    marginRight: 10,
                  }}
                  label="Email"
                  placeholder="seu@mail.com"
                  placeholderTextColor="#999"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  autoCorrect={false}
                  returnKeyType="next"
                  ref={inputEmailRef}
                  blurOnSubmit={false}
                  errorMessage={errors.email}
                  value={values.email}
                  onChangeText={handleChange("email")}
                  onBlur={handleBlur("email")}
                  containerStyle={{
                    paddingBottom: dinamicPadding(errors.email, touched.email),
                  }}
                />
                
                <Button title="Enviar Código" loading={loading} onPress={() => handleSubmit()} style={{
                  flex: 1,
                }}/>

                <Link text="Voltar" onPress={() => handleForgotPasswordCancel()} />

              </LoginInfo>
            )}
          </Formik>
        </Container>
      </KeyboardAwareScrollView>
  );
};

export default ForgotPassword;
