import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Formik, FormikHelpers } from "formik";
import React, { useRef, useState } from "react";
import { Button, Icon, Input } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import * as Yup from "yup";

import {
  CognitoIdentityProviderClient,
  SignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { MaskedTextInput } from "react-native-mask-text";
import EyeIcon from "../../components/EyeIcon";
import { AlertOS } from "../../hooks/AlertOS";
import { APPLOGO } from "../../image";
import { ActionColor } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import {
  Container,
  ContainerInput,
  InputLabel,
  InputTextError,
  LoginInfo,
  Logo,
  LogoContainer,
  WrapperInput,
} from "./styles";

type RouteParamList = { msg: String };

interface FormValues {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
  phone_number: string;
}

const SignUpValidationSchema = Yup.object({
  name: Yup.string().required("insira um nome"),
  email: Yup.string()
    .email("insira um email válido")
    .required("insira um email"),
  password: Yup.string()
    .min(8, " - mínimo 8 caracteres")
    .required("insira uma senha")
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      "- insira uma senha válida - deve conter no mínimo 8 caracteres e números (0-9)"
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "As senhas devem ser iguais")
    .min(8, " - mínimo 8 caracteres")
    .required("insira uma senha")
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      "- insira uma senha válida - deve conter no mínimo 8 caracteres e números (0-9)"
    ),
  phone_number: Yup.string()
    .min(
      11,
      "- insira um número válido (99) 9 9999-9999 - mínimo 11 caracteres"
    )
    .required("insira um telefone (99) 9 9999-9999"),
});

const SignUp: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(true);
  const inputNameRef = useRef<typeof Input>(null);
  const inputEmailRef = useRef<typeof Input>(null);
  const inputPasswordRef = useRef<typeof Input>(null);
  const inputConfirmPasswordRef = useRef<typeof Input>(null);
  const inputPhoneRef = useRef<typeof Input>(null);
  const initialValues: FormValues = {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    phone_number: "",
  }; //FIXME:remover dados de teste JAT123-abcd

  async function signUpCognito(user) {
    const client = new CognitoIdentityProviderClient({
      region: "us-east-1",
    });
    const CLIENT_ID = "15cgfpn93vpns37hs5f6jspu4c";
    console.log("CLIENT_ID", CLIENT_ID);
    const command = new SignUpCommand({
      Password: user.password,
      Username: user.email,
      UserAttributes: [
        { Name: "name", Value: user.name },
        { Name: "phone_number", Value: user.phone_number },
      ],
      ClientId: CLIENT_ID,
    });
    const response = await client.send(command);
    return { statusCode: response.$metadata.httpStatusCode, data: response };
  }

  async function handleSignUpRegister(
    formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) {
    try {
      setLoading(true);
      formValues.phone_number = `+55${formValues.phone_number}`;
      // const result = await api.post("/user/register", formValues);
      const result = await signUpCognito({ ...formValues });
      const username = formValues.email;
      setLoading(false);
      formikHelpers.resetForm();
      navigation.navigate("SignUpVerify", { username });
    } catch (err: any) {
      console.log(err);

      setLoading(false);
      let title, message;
      switch (err.name) {
        case "InvalidPasswordException":
          title = "Senha Inválida";
          message =
            "A senha não está conforme as políticas. Senha deve conter ao menos um caractere em CAPSLOCK, um numero e um caractere especial";
          break;
        case "UsernameExistsException":
          title = "Email já cadastrado";
          message = "O Email informado já está em uso. Tente outro email.";
          break;
        default:
          title = "Contate o suporte";
          message =
            "Desculpe o transtorno. Entre em contato com o nosso suporte para mais informações.";
          break;
      }
      AlertOS({
        title,
        message,
        toastType: "error",
      });
    }
  }

  return (
    <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
      <Container>
        <LogoContainer>
          <Logo source={APPLOGO} />
        </LogoContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={SignUpValidationSchema}
          validateOnChange={false}
          onSubmit={(values, formikHelpers) =>
            handleSignUpRegister(values, formikHelpers)
          }
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <LoginInfo>
              <Input
                leftIcon={
                  <FontAwesome name="user" size={24} color={ActionColor} />
                }
                leftIconContainerStyle={{
                  marginLeft: 2,
                  marginRight: 16,
                }}
                label="Nome"
                placeholder="Nome Completo"
                autoCapitalize="words"
                autoCorrect={false}
                returnKeyType="next"
                blurOnSubmit={false}
                errorMessage={errors.name}
                ref={inputNameRef}
                onSubmitEditing={() => inputEmailRef.current?.focus()}
                value={values.name}
                onChangeText={handleChange("name")}
                onBlur={handleBlur("name")}
                containerStyle={{
                  paddingBottom: dinamicPadding(errors.name, touched.name),
                }}
              />

              <Input
                leftIcon={
                  <MaterialIcons name="email" size={24} color={ActionColor} />
                }
                leftIconContainerStyle={{
                  marginLeft: 0,
                  marginRight: 10,
                }}
                label="Email"
                placeholder="seu@mail.com"
                placeholderTextColor="#999"
                keyboardType="email-address"
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                ref={inputEmailRef}
                blurOnSubmit={false}
                errorMessage={errors.email}
                onSubmitEditing={() => inputPasswordRef.current?.focus()}
                value={values.email}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                containerStyle={{
                  paddingBottom: dinamicPadding(errors.email, touched.email),
                }}
              />

              <Input
                leftIcon={
                  <MaterialIcons name="lock" size={24} color={ActionColor} />
                }
                rightIcon={
                  <EyeIcon
                    onChangeVisibility={setPasswordVisibility}
                    visibility={passwordVisibility}
                  />
                }
                leftIconContainerStyle={{
                  marginLeft: 0,
                  marginRight: 10,
                }}
                label="Senha"
                placeholder="******"
                secureTextEntry={passwordVisibility}
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                ref={inputPasswordRef}
                blurOnSubmit={false}
                errorMessage={errors.password}
                onSubmitEditing={() => inputConfirmPasswordRef.current?.focus()}
                value={values.password}
                onChangeText={handleChange("password")}
                onBlur={handleBlur("password")}
                containerStyle={{
                  paddingBottom: dinamicPadding(
                    errors.password,
                    touched.password
                  ),
                }}
              />

              <Input
                leftIcon={
                  <MaterialIcons name="lock" size={24} color={ActionColor} />
                }
                rightIcon={
                  <EyeIcon
                    onChangeVisibility={setConfirmPasswordVisibility}
                    visibility={confirmPasswordVisibility}
                  />
                }
                leftIconContainerStyle={{
                  marginLeft: 0,
                  marginRight: 10,
                }}
                label="Confirmar senha"
                placeholder="******"
                secureTextEntry={confirmPasswordVisibility}
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                ref={inputConfirmPasswordRef}
                blurOnSubmit={false}
                errorMessage={errors.confirm_password}
                onSubmitEditing={() => inputPhoneRef.current?.focus()}
                value={values.confirm_password}
                onChangeText={handleChange("confirm_password")}
                onBlur={handleBlur("confirm_password")}
                containerStyle={{
                  paddingBottom: dinamicPadding(
                    errors.confirm_password,
                    touched.confirm_password
                  ),
                }}
              />

              <WrapperInput>
                <InputLabel>Telefone</InputLabel>
                <ContainerInput>
                  <Icon
                    name={"phone-iphone"}
                    color={ActionColor}
                    style={{
                      marginRight: 14,
                    }}
                  />
                  <MaskedTextInput
                    style={{
                      flex: 1,
                      fontSize: 18,
                      paddingVertical: 8,
                    }}
                    mask="(99) 99999-9999"
                    placeholder="(99) 99999-9999"
                    placeholderTextColor={"rgb(134, 147, 158)"}
                    keyboardType="phone-pad"
                    onChangeText={(text: string, rawText: string): void => {
                      handleChange("phone_number")(rawText);
                    }}
                    onBlur={handleBlur("phone_number")}
                    ref={inputPhoneRef}
                    maxLength={15}
                    value={values.phone_number}
                    returnKeyType="next"
                    autoCapitalize="none"
                    autoCorrect={false}
                    onSubmitEditing={() => handleSubmit()}
                    autoComplete={undefined}
                  />
                </ContainerInput>
                <InputTextError
                  style={{
                    paddingBottom: dinamicPadding(
                      errors.phone_number,
                      touched.phone_number
                    ),
                  }}
                >
                  {errors.phone_number}
                </InputTextError>
              </WrapperInput>

              <Button
                title="Cadastrar"
                loading={loading}
                onPress={() => handleSubmit()}
              />

              <Button
                title="Voltar"
                loading={loading}
                onPress={() => navigation.navigate("SignIn")}
                type="outline"
                containerStyle={{
                  marginVertical: 1,
                }}
              />
            </LoginInfo>
          )}
        </Formik>
      </Container>
    </KeyboardAwareScrollView>
  );
};

export default SignUp;
