import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/theme';

export const Container = styled.View`
    align-items: center;
    padding: 24px;
`;

export const ContainerImage = styled.View`
    width: 260px;
    height: 260px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

export const WarningImage = styled.Image`
    width: 260px;
    height: 260px;
    object-fit: fill;
    padding: 20px 10px 10px 20px;
`;

export const Title = styled.Text`
    font-size: ${normalizeLimit(20)}px;
    font-weight: 200;
    text-align: center;
    margin: 16px 0 24px 0;
`;

export const Message = styled.Text`
    font-size:16px;
    line-height: ${normalizeLimit(20)}px;
    text-align: center;
    color: #8A8A8A;
`;