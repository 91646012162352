import styled from 'styled-components/native';

export const Container = styled.View`
  margin: 0px 0px 0px 0px;
  background: #FFFFFF;
  padding: 15px 15px 0px 15px;
  flex: 1;
`;

export const ResultContainer = styled.View`
  flex: 1;
  margin: 0px 0px 0px 0px;
`;

export const Title = styled.Text`
  font-size: 23px;
  font-weight: bold
`;

export const MachineList = styled.ScrollView`
  margin-top: 10px
`;

export const Item = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 0px
`;

export const ItemImage = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 30px
`;

export const ItemInfo = styled.View`
  margin-left: 15px
`;

export const ItemTitle = styled.Text`
  font-weight: bold;
  color: #333
`;

export const ItemDescription = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  align-items: center
`;

export const Star = styled.Text`
  font-size: 14px;
  color: #FF7C01
`;

export const Categories = styled.Text`
  font-size: 14px;
  color: #999
`;

export const Distance = styled.Text`
  font-size: 14px;
  color: #999
`;

export const TextInfo = styled.Text`
  font-size: 14px;
  color: #999
`;

export const Restriction = styled.Text`
  margin-top: 15px;
  font-size: 14px;
  color: #999;
`;

export const MachineListContainer = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: false,
})`
  flex: 1;
`;