import 'react-native-gesture-handler'
import React, { Fragment } from 'react';
import { Platform, StatusBar } from 'react-native';
import { AuthProvider } from "./src/contexts/auth";
import Routes from './src/routes/index.routes';
import { LogBox } from 'react-native';
import { ToastContainer } from 'react-toastify';


LogBox.ignoreLogs([
  "react-native-gesture-handler",
  "EventEmitter.removeListener"
]);

export default function App() {
  return (
    <Fragment>
      {Platform.OS === "web" && (
          <>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
              theme="light"
            />
          </>
        )}
      <StatusBar barStyle="dark-content" backgroundColor="#FFF"/>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </Fragment>
  );
};