import React, { useState, useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";

import { ResponseMachinesByName, Machine, MachineIdentification } from "../../models/machine";
import api from "../../services/api";
import { IMGMACHINE, LOCATION_IMAGE } from "../../image";

import   {
  Container,
  Title,
  MachineList,
  Item,
  ItemImage,
  ItemInfo,
  ItemTitle,
  ItemDescription,
  Star,
  Categories,
  Distance,
  Restriction,
  MachineListContainer
} from './styles';
import { ResultContainer } from "./styles";
import { TextInfo } from "./styles";

import { SearchBar } from "react-native-elements";
import { useAuth } from '../../contexts/auth';
import { formatDistance } from "../../utils/formatNumber";
import Warning from "../../components/Warning";
import Machines from "../../components/Machines";
import Loading from "../../components/Loading";

export default function Search() {
  const navigation = useNavigation();
  const [search, setSearch] = useState("");
  const [machines, setMachines] = useState<MachineIdentification[]>([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getMachines(name: string) {
      if(false && user?.nearmachines){
        //Busca local
        const filteredMachines = Array.from(user.nearmachines.values()).filter((item) => (item.name.toLowerCase().indexOf(name)!=-1));
        setMachines(filteredMachines);
      }else{
        //Busca remota
        if(name.length >= 3){
          setLoading(true);
          const response = await api.get<ResponseMachinesByName>(`/machine?s=${name}`);
          console.log("resulta da busca por ?:" + name);
          console.log(response);
          setMachines(response.data.machines);
          setLoading(false);
        }
      }
    }

    if (search !== "") {
      getMachines(search);
    } else {
      setMachines([]);
    }
  }, [search]);

  const search_handle = (text:string) => {
    setSearch(text);
    console.log("procurando por: " + text);
  }

  return (
    <Container>
      <SearchBar
        placeholder="Pesquise máquinas por nome..."
        onChangeText={search_handle}
        value={search}
        onCancel={() => setSearch("")}
      />
      <ResultContainer>
      <MachineList>
        <Machines
                title="Pontos de Venda por perto"
                machines={machines}
                display
              />
      </MachineList>

      </ResultContainer>
    </Container>
  );
}
