import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { ThemeProvider, normalize } from "react-native-elements";
import MenuLeft from "../components/Header/MenuLeft";
import MenuLeftScan from "../components/Header/MenuLeftScan";
import { useAuth } from "../contexts/auth";
import Address from "../pages/Address";
import CreditCard from "../pages/CreditCard";
import ForgotPassword from "../pages/ForgotPassword";
import ForgotPasswordVerify from "../pages/ForgotPasswordVerify";
import LoginScan from "../pages/LoginScan";
import MachineBasket from "../pages/MachineBasket";
import Payment from "../pages/Payment";
import PaymentConfirmed from "../pages/PaymentConfirmed";
import Pix from "../pages/Pix";
import PixError from "../pages/PixError";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SignUpVerify from "../pages/SignUpVerify";
import SplashScreen from "../pages/SplashScreen";
import { theme } from "../utils/theme";
import DashboardRoutes from "./dashboard.routes";
const Stack = createStackNavigator();

interface AuthRoutesScanProps {
  machineData: {
    machineId: string;
  };
}

//Rotas para usuários autenticados
const AuthRoutes: React.FC = () => (
  <NavigationContainer>
    <Stack.Navigator initialRouteName="DashboardRoutes" screenOptions={{headerMode:"screen", headerShown: false}} >
      <Stack.Screen
        name="DashboardRoutes"
        component={DashboardRoutes}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CreditCard"
        component={CreditCard}
        options={{
          headerTitleStyle: { display: "flex" },
          title: "Cartão de Crédito",
          headerShown: true,
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="Address"
        component={Address}
        options={{
          headerTitleStyle: { display: "flex" },
          title: "Endereço",
          headerShown: true,
          headerLeft: (props) => <MenuLeft />,
        }}
      />
    </Stack.Navigator>
  </NavigationContainer>
);

const AuthRoutesScan: React.FC<AuthRoutesScanProps> = (props) => (
  <NavigationContainer>
    <Stack.Navigator initialRouteName="MachineBasket" screenOptions={{headerMode:"screen"}} >
      <Stack.Screen
        name="MachineBasket"
        component={MachineBasket}
        initialParams={{ machineId: props.machineData.machineId }}
        options={{
          headerTitleStyle: { fontSize: normalizeLimit(15) },
          title: "Seus produtos estarão em:",
          headerLeft: (props) => <MenuLeftScan />,
        }}
      />
      <Stack.Screen
        name="Payment"
        component={Payment}
        options={{
          headerTitleStyle: { fontSize: normalizeLimit(15) },
          title: "Seus produtos estarão em:",
          headerLeft: (props) => <MenuLeft />,
        }}
      />

      <Stack.Screen
        name="Pix"
        component={Pix}
        options={{
          headerTitleStyle: { fontSize: normalizeLimit(15) },
          title: "Pagamento Pix",
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="PixError"
        component={PixError}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="PaymentConfirmed"
        component={PaymentConfirmed}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  </NavigationContainer>
);

//Rotas para usuários não autenticados
const AppRoutes: React.FC = () => {
  const { user } = useAuth();
  return (
    <NavigationContainer>
      <Stack.Navigator
        initialRouteName={user && user.status == 1 ? "SignUpVerify" : "SignIn"}
      >
        <Stack.Screen
          name="SignIn"
          component={SignIn}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ForgotPassword"
          component={ForgotPassword}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ForgotPasswordVerify"
          component={ForgotPasswordVerify}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SignUp"
          component={SignUp}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SignUpVerify"
          component={SignUpVerify}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="LoginScan"
          component={LoginScan}
          options={{
            headerTitleStyle: { display: "flex" },
            title: "Escanear Qrcode",
            headerShown: true,
            headerLeft: (props) => <MenuLeft />,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

const Routes: React.FC = () => {
  const { signed, loading, isAuthScan, machineId } = useAuth();

  //se tiver dúvidas em rotas ou autenticação
  //veja: https://www.youtube.com/watch?v=KISMYYXSIX8&t=676s
  //return <AuthRoutes />; //para teste
  //return <AppRoutes />; //para teste das rotas de autenticação
  //return <ThemeProvider theme={theme}><AppRoutes /></ThemeProvider>;
  //return <ThemeProvider theme={theme}><AuthRoutes /></ThemeProvider>;

  //return  signed ? <AuthRoutes />: <AppRoutes />;

  if (loading) {
    return <SplashScreen />;
  }

  if (isAuthScan) {
    return signed ? (
      <ThemeProvider theme={theme}>
        <AuthRoutesScan machineData={{ machineId }} />
      </ThemeProvider>
    ) : (
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    );
  }

  return signed ? (
    <ThemeProvider theme={theme}>
      <AuthRoutes />
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  );
};

export default Routes;
