import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { RefreshControl, Text } from "react-native";
import InputSpinner from "react-native-input-spinner";
import Loading from "../../components/Loading";
import { IMGPRODUCT, NO_ITENS } from "../../image";
import { MachineIdentification } from "../../models/machine";
import { Item, Product } from "../../models/product";
import api from "../../services/api";
import { normalizeLimit } from "../../utils/theme";
//import { ScrollView } from "react-native-gesture-handler";

import { Button } from "react-native-elements";
import { MachineSelectedView } from "../../components/Machines";
import Warning from "../../components/Warning";
import { AlertOS } from "../../hooks/AlertOS";
import { ActionColor, colorWhite } from "../../utils/colors";
import { formatCurrency } from "../../utils/formatNumber";
import {
  Container,
  InfoPurchase,
  ItemAvailability,
  ItemHeaderInfor,
  ItemImage,
  ItemInfo,
  ItemQuantify,
  ItemTag,
  ItemTitle,
  Price,
  ProductItem,
  ProductList,
  ProductsAvaliable,
  PurchaseTotal,
} from "./styles";

type RouteParamList = { machineId: string; machine: MachineIdentification };

const MachineBasket: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const navigation = useNavigation();
  const [machine, setMachine] = useState<MachineIdentification>(
    route.params?.machine
  );
  const [machineId] = useState<string>(route.params?.machineId);
  const [loading, setLoading] = useState(false);
  const [basket, setBasket] = useState<Map<string, Item>>(
    new Map<string, Item>()
  );
  const [refreshing, setRefreshing] = useState(false);
  const [total, setTotal] = useState(0);

  async function getMachine() {
    try {
      setLoading(true);
      const response = await api.get(`/machine/${machineId}`);
      const { machines } = response.data;
      setLoading(false);
      setMachine(machines);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  function _handleItemList(item: Item) {
    return (
      <ProductItem key={item.product.id}>
        <ItemTag>
          <ItemAvailability>{item.quantity}</ItemAvailability>
          <ItemImage
            source={
              item.product.image ? { uri: item.product.image } : IMGPRODUCT
            }
          />
        </ItemTag>
        <ItemInfo>
          <ItemHeaderInfor>
            <ItemTitle>{item.product.name}</ItemTitle>
          </ItemHeaderInfor>
          <ItemQuantify>
            <InputSpinner
              max={item.quantity}
              min={0}
              step={1}
              colorMax={colorWhite}
              colorMin={colorWhite}
              rounded={false}
              value={
                basket.has(item.product.id)
                  ? basket.get(item.product.id)?.quantity
                  : 0
              }
              onChange={(num: number) => {
                if (num > 0) {
                  let basketitem = basket.get(item.product.id);
                  if (basketitem) {
                    basketitem.quantity = num;
                  } else {
                    const newItem: Item = {
                      product: item.product,
                      quantity: num,
                    };
                    basket.set(item.product.id, newItem);
                  }
                }
                let sum: number = 0;
                basket.forEach(
                  (item, key) => (sum += item.quantity * item.product.price)
                );

                setTotal(sum);
                // console.log("Quantidade de %s alterado para %s agora a cesta tem %s distintos e totaliza %s", item.product.id,num, basket.size, sum);
                // console.log(
                //   "Quantidade de %s alterado para %s agora a cesta tem %s distintos e totaliza %s",
                //   item.product.id,
                //   num,
                //   basket.size,
                //   sum
                // );
              }}
              onMin={() => {
                basket.delete(item.product.id);
              }}
              inputStyle={{ width: 20 }}
              style={{ width: normalizeLimit(50) }}
              colorRight={colorWhite}
              colorLeft={colorWhite}
              color={colorWhite}
              colorPress={colorWhite}
              textColor="#333333"
              fontSize={normalizeLimit(17)}
              buttonTextColor={ActionColor}
              buttonStyle={{ backgroundColor: "transparent" }}
            />
            <Price>{formatCurrency(item.product.price / 100)}</Price>
          </ItemQuantify>
        </ItemInfo>
      </ProductItem>
    );
  }

  useEffect(() => {
    const initialLoading = async () => {
      await getMachine();
    };
    initialLoading();
  }, []);

  return loading ? (
    <Loading text="Carregando..." />
  ) : (
    <Container>
      {MachineSelectedView(navigation, machine ? machine : getMachine())}
      {!machine.products.length >= 1 && !refreshing && (
        <Warning
          imageSource={NO_ITENS}
          title="Não há produtos nesta máquina"
        ></Warning>
      )}
      {refreshing ? (
        <Loading text={"Buscando produtos..."} />
      ) : (
        <ProductList
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={getMachine}
              enabled={true}
            />
          }
        >
          {machine?.products?.map((item: Product) =>
            item ? _handleItemList(item) : <Text />
          )}
        </ProductList>
      )}
      <InfoPurchase>
        <ProductsAvaliable>Itens a venda</ProductsAvaliable>
        <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
      </InfoPurchase>

      <Button
        title="Finalizar Compra"
        loading={loading}
        onPress={() => {
          total == 0
            ? AlertOS({
                title: "Selecione um produto",
                message:
                  "Não existem produtos no carrinho. Selecione um produto para prosseguir",
                toastType: "info",
              })
            : navigation.navigate("Payment", {
                machine,
                basket,
              });
        }}
      />
    </Container>
  );
};

export default MachineBasket;
