import { Location } from './location';
import { Product } from './product';

export interface Machine extends MachineIdentification {
  products?: Item[];
  restriction?: string;
  categories?: string[];
  star?: number;
}

export interface Item {
  quantity: number;
  product: Product;
}

export interface MachineIdentification {
  id: string;
  name: string;
  logo: string;
  location: Location;
  distance?: number;
  status: number;//1 - ativa; 2 - desativada ; 3 - vazia
  products: []
}

export enum EnumStatusMachine {
  ACTIVE = 1,
  INACTIVE = 2
}

//Intefaces de comunicação
export interface ResponseMachinesByLocation {
  message: string;
  machines: MachineIdentification[];
}

export interface ResponseMachineById {
  message: string;
  machine: Machine;
}

export interface ResponseMachinesByName {
  message: string;
  machines: MachineIdentification[];
}

export interface ResponseMachineProducts {
  message: string;
  machineProducts: Item[];
}