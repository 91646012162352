import { RouteProp, useRoute } from "@react-navigation/native";
import React, { Fragment, useEffect, useState } from "react";
import { SearchBar } from "react-native-elements";

import { MachineListView } from "../../components/Machines";
import { Category, ResponseCategory } from "../../models/category";
import {
  MachineIdentification,
  ResponseMachinesByName,
} from "../../models/machine";
import api from "../../services/api";
import {
  CategoriesList,
  CategoryItem,
  CategoryItemImage,
  CategoryItemTitle,
  Container,
  ResultContainer,
  Title,
} from "./styles";

type RouteParamList = { searchkeyword: string };

export default function SearchCategory() {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchkeyword, setSearchkeyword] = useState(
    route?.params?.searchkeyword
  );
  const [search, setSearch] = useState("");
  const [machines, setMachines] = useState<MachineIdentification[]>([]);

  useEffect(() => {
    async function showCategories() {
      // Obter dados da rota 'categories' da api fake
      const response = await api.get<ResponseCategory>("/category");
      const cate: Category[] = response?.data.categories as Category[];
      setCategories(cate);
      console.log("categories:");
      console.log(cate);
    }
    showCategories();
  }, []);

  useEffect(() => {
    async function getMachines(name: string) {
      const response = await api.get<ResponseMachinesByName>(
        `/machine?s=${name}`
      );
      setMachines(response.data.machines);
    }
    if (search && search !== "") {
      getMachines(search);
    } else {
      setMachines([]);
    }
  }, [search]);

  useEffect(() => {
    async function getMachines(searchkeyword: string) {
      const response = await api.get<ResponseMachinesByName>(
        `/machine?c=${searchkeyword}`
      );
      setMachines(response.data.machines);
    }
    if (searchkeyword && searchkeyword !== "") {
      getMachines(searchkeyword);
    } else {
      setMachines([]);
    }
  }, [searchkeyword]);

  const onPressCategory = (item) => {
    setSearchkeyword(item.keyword);
  };

  return (
    <Container>
      <SearchBar
        placeholder="Digite o nome do item"
        onChangeText={(text) => setSearch(text)}
        value={search}
        onCancel={() => setSearch("")}
      />
      <ResultContainer>
        {machines.length > 0 ? (
          <MachineListView machines={machines} />
        ) : (
          <Fragment>
            <Title>Categorias</Title>
            <CategoriesList
              data={categories}
              keyExtractor={(item) => String(item.id)}
              renderItem={({ item }) => (
                <CategoryItem onPress={onPressCategory} key={item.id}>
                  <CategoryItemImage source={{ uri: item.photo }} />
                  <CategoryItemTitle>{item.title}</CategoryItemTitle>
                </CategoryItem>
              )}
            />
          </Fragment>
        )}
      </ResultContainer>
    </Container>
  );
}
