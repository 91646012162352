import { CognitoIdentityProviderClient, GetUserCommand, InitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";
import "react-native-get-random-values";
import "react-native-url-polyfill/auto";
import api from "./api";


//Futuramente essa classe tratará da renovação de token expirados  
//TODO: checagem de expiração de token e renovação

interface UserAttributes {
  sub: string;
  name: string;
  email: string;
  phone_number: string;
}

export async function signIn(email: string, password: string) {
  const CLIENT_ID = '15cgfpn93vpns37hs5f6jspu4c'
  // const response = await api.post<ResultLogin>("/user/login", {Username: email, Password: password });
  // return response
  const client = new CognitoIdentityProviderClient({
    region: "us-east-1"
  });

  const initiateAuthCommandCommand = new InitiateAuthCommand({
    AuthFlow: "USER_PASSWORD_AUTH",
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password,
    },
    ClientId: CLIENT_ID
  });

  const response = await client.send(initiateAuthCommandCommand);
  const idToken = await response.AuthenticationResult?.IdToken
  const refreshToken = await response.AuthenticationResult?.RefreshToken
  const accessToken = await response.AuthenticationResult?.AccessToken

  const getUserCommand = new GetUserCommand({ AccessToken: accessToken })
  const responseGetUser = await client.send(getUserCommand);

  let userAttributes: UserAttributes = {
    sub: "",
    name: "",
    email: "",
    phone_number: ""
  }

  if (responseGetUser.UserAttributes) {
    for (let attribute of responseGetUser.UserAttributes) {
      userAttributes[attribute.Name] = attribute.Value
    }
    const response = await api.get("/user", {
      params: {
        "id": userAttributes.sub
      }, headers: {
        "Authorization": accessToken
      }
    }
    );
    const { users } = response.data;
    const user = {
      id: userAttributes.sub,
      name: users.name,
      email: userAttributes.email,
      phone: users.phone ? users.phone : "",
      cpf: users.cpf ? users.cpf : "",
      balance: users.balance,
      creditcards: users.creditcards ? JSON.parse(users.creditcards) : [],
      purchases: [],
      status: 2
    };

    const data = {
      user,
      idToken,
      accessToken,
      refreshToken
    }

    return { data }
  }
}