import { useNavigation, useRoute } from "@react-navigation/native";
import React from "react";
import { Button } from "react-native-elements";

import { formatCurrency } from "../../utils/formatNumber";

import { PIX_IMAGE_ERROR } from "../../image";

import {
  AppInfo,
  BasketInfo,
  Container,
  InfoContent,
  Message,
  PixImage,
  ProductInfo,
  ProductItem,
  ProductList,
  ProductTotal,
  ProductsTitle,
  PurchaseTotal,
  Title,
} from "./styles";

import { Item } from "../../models/product";

const PixError: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();

  const { total, itens } = route.params;
  const navigation = useNavigation();

  return (
    <Container>
      <InfoContent>
        <AppInfo>
          <PixImage source={PIX_IMAGE_ERROR} />
        </AppInfo>
        <Title>Pagamento não realizado</Title>
        <Message>
          O pagamento via Pix não foi confirmado. Que tal tentar fazer seu
          pedido novamente?
        </Message>

        <BasketInfo>
          <ProductsTitle>Resumo da compra</ProductsTitle>
          <ProductList>
            {itens.map((item: Item) => (
              <ProductItem key={item.product.id}>
                <ProductInfo>
                  {item.product.name} x{item.quantity}
                </ProductInfo>
                <ProductTotal>
                  {" "}
                  {formatCurrency((item.quantity * item.product.price) / 100)}
                </ProductTotal>
              </ProductItem>
            ))}
          </ProductList>
          <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
        </BasketInfo>
      </InfoContent>

      <Button
        onPress={() => navigation.navigate("Home")}
        title="Formas de pagamento"
      />
    </Container>
  );
};

export default PixError;
