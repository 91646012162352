import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';
import { normalizeLimit } from '../../utils/theme';

export const Container = styled.View`
  margin: 0px 0px 0px 0px;
  background: #fff;
  padding: 5px 15px 0px 15px;
  flex: 1;
  `;

export const Title = styled.Text`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${ActionColor};
  margin: 10px 0px 10px 0px;
  flex:1;
`;

export const LoginInfo = styled.View`
  flex: 1;
  flex-direction: column;
`;


export const LogoContainer = styled.View`
  align-items: center;
  margin: 30px 0;
`;

export const Logo = styled.Image`
  width: 140px;
  height: 140px;
  margin: 0;
`;

export const LoginActionGroup = styled.View`
  display: flex;
  flex-direction: column;
  margin: 0px 10px 0px 10px;
  justify-content: center;
  align-items: stretch;
  `;

export const ContainerTextGroup = styled.View`
  flex: 1;
  justify-content: space-between;
`

export const ForgotPasswordGroup = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TextSignUpGroup = styled.View`
  flex-direction: row;
  justify-content: center; 
  align-items: center;
`;

export const TextGroup = styled.Text`
  color: rgb(153, 153, 153);
`;

export const BottomContainer = styled.View`
  margin-bottom: ${normalizeLimit(32)}px;
`;

export const QRCodeContainer = styled.View`
  align-items: center;
  margin: 0 9px;
  margin-bottom: 16px;
`;

export const QRCodeButton = styled.TouchableOpacity`
  width: 56px;
`;

export const QRCodeText = styled.Text`
margin-top: 8px;
  color: rgb(153, 153, 153);
`;