import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/theme';

export const Container = styled.View`
  margin: 20px 0 20px 20px;
`;

export const Header = styled.View`
  display: ${props => props.display ? 'flex' : 'none'};
`;


//TODO: padroniza tamanhos de títulos usando variável global
export const Title = styled.Text`
  font-size: ${normalizeLimit(17)}px;
  font-weight: bold;
`;

export const MachineList = styled.ScrollView`
  margin-top: 10px;
`;

export const Item = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 15px;
  opacity: ${props => props.active};
`;

export const ItemImage = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  opacity: ${props => props.active};
`;

export const ItemInfo = styled.View`
  display: flex;
  flex-shrink: 1;
  margin-left: 15px;
  opacity: ${props => props.active};
`;

export const ItemTitle = styled.Text`
  flex: 1;
  font-weight: bold;
  color: #333;
`;

export const ItemDescription = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  align-items: center;
  flex-shrink:1;
`;

export const Star = styled.Text`
  font-size: 14px;
  color: #FF7C01;
`;

export const Categories = styled.Text`
  font-size: 14px;
  color: #999;
`;

export const Distance = styled.Text`
  font-size: 14px;
  color: #999;
`;

export const Restriction = styled.Text`
  margin-top: 15px;
  font-size: 14px;
  color: #999;
`;

export const MachineImage = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 30px;
`;

export const TextInfo = styled.Text`
  flex: 1;
  flex-shrink: 1;
  font-size: 14px;
  color: #999;
`;


//Machines
export const MachineSelected = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 0px;
`;

export const MachineTitle = styled.Text`
  font-weight: bold;
  color: #333;
  margin-left: 0px;
  margin-right: 15px
`;

export const MachineInfo = styled.View`
  display: flex;
  flex-shrink: 1;
  margin-left: 15px;
`;

/*
export const Categories = styled.Text`
  font-size: 14px;
  color: #999
`;

export const Star = styled.Text`
  font-size: 14px;
  color: #FF7C01
`;

export const Distance = styled.Text`
  font-size: 14px;
  color: #999
`;

*/