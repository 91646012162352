import styled from 'styled-components/native';
import { colorRed } from '../../utils/colors';
import { normalizeLimit } from '../../utils/theme';

export const Container = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: false
})`
  flex: 1;
  background: #FFF
`;

export const Details = styled.View`
  margin: 10px 20px 0 20px
`;

export const ItemImage = styled.Image`
  height: ${normalizeLimit(150)}px;
  border-radius: 5px
`;

export const ItemTitle = styled.Text`
  font-size: ${normalizeLimit(20)}px;
  color: #333;
  font-weight: bold;
  margin-top: 10px
`;

export const BackButton = styled.TouchableOpacity``;

export const ItemIngredient = styled.Text`
  font-size: ${normalizeLimit(18)}px;
  color: #999;
  margin-top: 10px
`;

export const Info = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 10px
`;

export const ItemPrice = styled.Text`
  color: green;
  font-size: ${normalizeLimit(20)}px
`;

export const OldPrice = styled.Text`
  margin-left: 5px;
  color: #999;
  font-size: ${normalizeLimit(20)}px;
  text-decoration: line-through
`;

export const Wrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center
`;

export const OfferInfoGroup = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 0px 0px 5px;
`;

export const Delivery = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-width: 1px;
  border-color: #EEE;
  border-radius: 2px;
  padding: 15px
`;

export const OfferMachineTittle = styled.Text`
  font-size: ${normalizeLimit(14)}px;
  color: #333;
`;

export const OfferLocation = styled.Text`
  font-size: ${normalizeLimit(14)}px;
  color: #333;
`;

export const OfferRestriction = styled.Text`
  font-size: ${normalizeLimit(14)}px;
  color: ${colorRed};
`;

export const OfferDistance = styled.Text`
  margin-left: 10px
`;

export const LeftHeader = styled.View``;

export const DrinkInfo = styled.View``;

export const DrinkHeader = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${normalizeLimit(20)}px;
  background: #EEE;
  padding: ${normalizeLimit(15)}px
`;

export const Message = styled.Text`
  color: #333;
  font-size: ${normalizeLimit(18)}px;
  font-weight: bold
`;

export const SubMessage = styled.Text`
  color: #999
`;

export const DrinkItem = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${normalizeLimit(20)}px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const DrinkTitle = styled.Text`
  font-size: ${normalizeLimit(14)}px
`;

export const DrinkPrice = styled.Text`
  color: #999;
  font-weight: bold;
  font-size: ${normalizeLimit(13)}px
`;

export const Warning = styled.View`
  background: #333;
  padding: 3px;
  border-radius: 2px;
`;

export const WarningText = styled.Text`
  font-size: ${normalizeLimit(13)}px;
  color: #FFF
`;