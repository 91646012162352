import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Container, Button } from './styles';
import { MaterialIcons } from '@expo/vector-icons';
import { ActionColor } from '../../../utils/colors';
import { useAuth } from '../../../contexts/auth';

// navigation prop
function MenuLeftScan() {
  const navigation = useNavigation();
  const { signOut } = useAuth();
  return (
    <Container>
      {/* Navegar para 'Wallet' */}
      <Button onPress={() => signOut()}>
        <MaterialIcons
          name="keyboard-arrow-left"
          color={ActionColor}
          size={25}
        />
      </Button>
    </Container>
  );
};

export default MenuLeftScan;