import React from 'react';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';

import Categories from '../../components/Categories';
import Machines from '../../components/Machines';

import
  {
    Container,
    BackButton,
    ExportButton
  } from './styles';

export default function Hinting() {
  return (
    <Container>
      <Categories />
      <Machines title="Pontos de Venda" display="true"/>
    </Container>
  );
}

