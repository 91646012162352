import styled from 'styled-components/native';
import {normalizeLimit} from '../../utils/theme';

export const Container = styled.SafeAreaView`
  flex: 1;
  background: #FFF;
  padding: 0 ${normalizeLimit(15)}px
`;

export const Title = styled.Text`
  font-size: ${normalizeLimit(17)}px;
  color: #333;
  margin: ${normalizeLimit(15)}px;
  font-weight: bold
`;

export const CategoriesList = styled.FlatList.attrs({
  numColumns: 2,
  horizontal: false,
  showsVerticalScrollIndicator: false
})`
  flex: 1;
  margin: 0px ${normalizeLimit(10)}px
`;

export const CategoryItem = styled.TouchableOpacity`
  flex: 1;
  margin: ${normalizeLimit(10)}px ${normalizeLimit(5)}px
`;

export const CategoryItemImage = styled.Image`
  width: ${normalizeLimit(150)}px;
  height: ${normalizeLimit(60)}px;
  border-radius: 4px;
`;

export const CategoryItemTitle = styled.Text`
  position: absolute;
  top: ${normalizeLimit(10)}px;
  left: ${normalizeLimit(10)}px;
  color: #FFF;
  font-size: ${normalizeLimit(16)}px;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px
`;

export const ResultContainer = styled.View`
  margin: 0px 0px 0px 0px
`;
