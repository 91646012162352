import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';
import { normalizeLimit } from '../../utils/theme';

interface Props {
  isActive: boolean
}
export const Container = styled.View`
  background: #ffffff;
  flex: 1;
  margin: 0;
  padding: 5px 15px 0px 15px;
`

export const ContainerScroll = styled.ScrollView`
`;

export const ResultContainer = styled.View`
  margin: 0px 0px 0px 0px
`;

export const BasketInfo = styled.View`
  display: flex;
  flex-direction: column;
  padding: 5px 5px 0px 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 0px
`;

export const Title = styled.Text`
  font-size: 23px;
  font-weight: bold
`;

export const ProductList = styled.ScrollView`
  margin-top: 2px;
  height: ${normalizeLimit(80)}px;
  flex-direction: column;
`;

export const ProductItem = styled.View`
  display: flex;
  flex-direction: row;
  margin: 2px 2px 0px 0px;
`;

export const ProductInfo = styled.Text`
  font-size: ${normalizeLimit(12)}px;
  color: #333333;
  margin-left: 0;
  margin-right: 2px
`;

export const ProductTotal = styled.Text`
  margin-left: 0px;
  font-size: ${normalizeLimit(12)}px;
  font-weight: bold;
  color: #333333;
`;

export const PurchaseTotal = styled.Text`
  flex: 1;
  font-weight: bold;
  color: #333;
  margin-left: 0px;
  padding: 10px 10px 10px 0px;
  align-items: flex-end;
  text-align: right;
  font-size: ${normalizeLimit(15)}px;
`;

export const ContainerPayment = styled.View`
  flex: 1;
  margin-top: 10px;
`;

export const ContentPayment = styled.View`
  margin-bottom: 20px;
`;

export const CreditCardList = styled.View`
  flex: 1;
  margin-top: 10px;
`;

export const PixOption = styled.View`
  margin-top: 10px;
`;

export const CardItem = styled.TouchableOpacity<Props>`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin-bottom: 20px;
  border: none;
`

export const PixItem = styled.TouchableOpacity<Props>`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px;
  border-width: 1px;
  border-color: rgba(0,0,0,.1);
  border-color: ${(props: { isActive: any; }) => props.isActive ? ActionColor : 'rgba(0,0,0,.1)'};
  border-radius: 5px;
  margin-bottom: 20px;
`

export const CreditCardItem = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 0px
`;

export const ItemImage = styled.Image` 
  width: ${normalizeLimit(29)}px;
  height: ${normalizeLimit(21)}px;
  margin-right: 5px;
`;

export const ItemImagePix = styled.Image` 
  width: ${normalizeLimit(24)}px;
  height: ${normalizeLimit(24)}px;
  margin-right: 5px;
`;


export const PixImage = styled.Image` 
  width: ${normalizeLimit(24)}px;
  height: ${normalizeLimit(24)}px;
  margin-right: 14px
`;

export const ItemInfo = styled.View`
  flex: 1;
  flex-direction: row;
  margin-left: 15px
`;

export const ItemTitle = styled.Text`
  font-weight: bold;
  color: #333;
  margin-left: 15px;
  margin-right: 15px
`;

export const ProductsTitle = styled.Text`
  font-weight: bold;
  color: #333;
  margin: 0px 15px 0px 0;
`;

export const ItemDescription = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  align-items: center;

`;

export const TextInfo = styled.Text`
  font-size: 14px;
  color: #999
`;

export const CenteredView = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  background-color: gray;
`;

export const FormContainer = styled.View`
  margin: 20px;
  background-color: #fff;
  padding: 35px;
`;

export const InfoCreditCards = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 0px 0px;
`;

export const InputWrapper = styled.View`
  width: 100%;
`;

export const InputColumn = styled.View`
  flex: 1;
`;

export const InputColumnGroup = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const CreditCardsAvaliable = styled.Text`
  font-weight: bold;
  color: #333;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 10px;
  margin-top: 0px;
  margin-right: 0px;
  font-size: ${normalizeLimit(16)}px;
`;

export const Marker = styled.View`
  flex: 1;
  margin-top: 0px;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
  align-items: flex-end;
`;

export const WrapperInput = styled.View`
  margin-bottom: ${normalizeLimit(8)}px;
  margin: 0px ${normalizeLimit(10)}px;
`;

export const InputLabel = styled.Text`
  color: rgb(134, 147, 158);
  font-weight: bold;
  font-size: ${normalizeLimit(16)}px;
`;

export const ContainerInput = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${normalizeLimit(4)}px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(134, 147, 158);
`;

export const InputTextError = styled.Text`
  color: rgb(255, 86, 101);
  margin: ${normalizeLimit(5)}px;
`;