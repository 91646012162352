import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';
import { normalizeLimit } from '../../utils/theme';

export const Container = styled.ScrollView.attrs({
  contentContainerStyle: props => {
    return {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  showsVerticalScrollIndicator: false
})`
  padding: ${normalizeLimit(20)}px;
  background-color: #F3F2F3;
`;


export const PurchaseList = styled.ScrollView`
`;


export const Item = styled.View`
  margin-top: ${normalizeLimit(20)}px;
`;


export const DateContainer = styled.View`
  width: 100%;
  margin-bottom: ${normalizeLimit(12)}px;
`;

export const Date = styled.Text`
  font-size: ${normalizeLimit(16)}px;
  color: #333;
  font-weight: bold
`;

export const Casing = styled.View`
  background: #FFF;
  margin-top: ${normalizeLimit(20)}px;
  padding: ${normalizeLimit(10)}px;
  border-radius: 4px;
  box-shadow: 10px 2px 10px rgba(0, 0, 0, 0.1)
`;

export const Machine = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

export const MachineImage = styled.Image`
  width: ${normalizeLimit(60)}px;
  height: ${normalizeLimit(60)}px;
  border-radius: ${normalizeLimit(30)}px
`;

export const Info = styled.View`
  margin-left: ${normalizeLimit(15)}px
`;

export const Name = styled.Text.attrs({
  numberOfLines: 1
})`
  color: #333;
  font-weight: bold;
  font-size: ${normalizeLimit(16)}px
`;

export const OrderNumber = styled.Text`
  font-size: ${normalizeLimit(16)}px;
  font-weight: bold;
  color: #999
`;

export const OrderDetails = styled.View`
  margin-left: auto;
  margin-right: ${normalizeLimit(16)}px;
`;


export const Order = styled.Text`
  color: ActionColor;
  font-weight: 700;
  margin-top: ${normalizeLimit(8)}px;
  font-size: ${normalizeLimit(16)}px;
`;

export const Evaluation = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${normalizeLimit(10)}px 0 ;
  border-bottom-width: 1.1px;
  border-color: #CDC
`;

export const Message = styled.Text`
  font-size: ${normalizeLimit(16)}px;
  color: #999
`;

export const Star = styled.Text``;

export const Menu = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: ${normalizeLimit(10)}px 0;
`;

export const MenuButton = styled.TouchableOpacity`
  padding: 5px;
  margin: 0px ${normalizeLimit(30)}px 0px ${normalizeLimit(30)}px;
`;

export const MenuText = styled.Text`
  color: ${props => props.color || ActionColor};
`;

export const Wrapper = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${normalizeLimit(20)}px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: ${normalizeLimit(4)}px ${normalizeLimit(2)}px 0px ${normalizeLimit(2)}px;
  background: #fff;
`;
