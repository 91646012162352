//Produção api

import { CognitoIdentityProviderClient, InitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";
import { COGNITO_CLIENT_ID } from '@env';
import axios from "axios";
import { BASE_URL_CHAT } from "../config/environment";
import userService from "./userservice";


const apiChat = axios.create({ baseURL: BASE_URL_CHAT, });

apiChat.interceptors.request.use(request => {
  return request
}, error => {
  console.log(error)
})

apiChat.interceptors.response.use(response => {
  return response
}, error => {
  const status = error.response ? error.response.status : null
  if (status === 401) {
    return refreshTokens().then((tokens) => {
      apiChat.defaults.headers['Authorization'] = `${tokens?.accessToken}`;
      error.config.headers['Authorization'] = `${tokens?.accessToken}`;
      return axios.request(error.config);
    })
      .catch(err => err);
  }
  return Promise.reject(error);
});

async function refreshTokens() {
  const refreshToken = await userService.getRefreshToken();
  const cognito_client_id = '15cgfpn93vpns37hs5f6jspu4c';
  const client = new CognitoIdentityProviderClient({
    region: "us-east-1"
  });
  if (refreshToken) {
    const command = new InitiateAuthCommand({
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: cognito_client_id,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken
      }
    });
    const response = await client.send(command);
    if (response.AuthenticationResult?.AccessToken) {
      await userService.setLogedToken(response.AuthenticationResult?.AccessToken);
    }
    if (response.AuthenticationResult?.RefreshToken) {
      await userService.setRefreshToken(response.AuthenticationResult?.RefreshToken);
    }
    return { token: response.AuthenticationResult?.IdToken, accessToken: response.AuthenticationResult?.AccessToken }
  }
}

export default apiChat;