import { MaterialIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Formik, FormikHelpers } from "formik";
import React, { useRef, useState } from "react";
import { Button, Input } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";

import {
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import Link from "../../components/Link";
import { AlertOS } from "../../hooks/AlertOS";
import { APPLOGO } from "../../image";
import { ActionColor } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import {
  AppMessage,
  Container,
  LoginInfo,
  Logo,
  LogoContainer,
  Message,
  Title,
} from "./styles";

type RouteParamList = { username: string };

interface FormValues {
  code: string;
  password: string;
}

interface confirmForgotPassword {
  username: string;
  code: string;
  password: string;
}

const ForgotPasswordVerifyValidationSchema = Yup.object().shape({
  code: Yup.number()
    .min(6, "insira um codigo válido - mínimo 6 caracteres")
    .required("insira um codigo de verificação"),
  password: Yup.string()
    .min(8, " - mínimo 8 caracteres")
    .required("insira uma senha")
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      "- insira uma senha válida - deve conter no mínimo 8 caracteres e números (0-9)"
    ),
});

async function confirmForgotPassword(user: confirmForgotPassword) {
  const client = new CognitoIdentityProviderClient({
    region: "us-east-1",
  });

  const CLIENT_ID = "15cgfpn93vpns37hs5f6jspu4c";
  console.log("CLIENT_ID", CLIENT_ID);

  const command = new ConfirmForgotPasswordCommand({
    Username: user.username,
    ConfirmationCode: user.code,
    ClientId: CLIENT_ID,
    Password: user.password,
  });

  const response = await client.send(command);

  return response;
}

const ForgotPasswordVerify: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const email = route.params.email;
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const inputCodeRef = useRef<typeof Input>(null);
  const inputPasswordRef = useRef<typeof Input>(null);
  const initialValues: FormValues = {
    code: "",
    password: "",
  };

  async function handleForgotPasswordVerifyRegister(
    formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) {
    try {
      setLoading(true);
      const username = route.params.username;
      const { code, password } = formValues;
      const result = await confirmForgotPassword({ username, code, password });

      if (result.$metadata.httpStatusCode == 200) {
        // setContextUser({id: user?.id, name: user?.name, email: user?.email, status: 2} as User)
        //   //FIXME: para evitar do novo usuário precisar logar seria necessário efetuar o login automaticamente nesse ponto.
        //   console.log(newMessage, result.data);
        // }
      }
      setLoading(false);
      //TODO: logar automaticamente
      formikHelpers.resetForm();
      AlertOS({
        title: "Senha Atualizada",
        message: "Verificação efetuada! Você já pode logar",
      });
      navigation.navigate("SignIn", {
        msg: "Verificação efetuada! Você já pode logar",
      });
    } catch (err: any) {
      setLoading(false);
      console.log("Algo deu errado, tente novamente!", err);
      let title, message;
      switch (err.name) {
        case "CodeMismatchException":
          title = "Código de Verificação";
          message =
            "Código de verificação não corresponde ao enviado para o email";
          break;
        case "InvalidPasswordException":
          title = "Senha Inválida";
          message =
            "A senha não está conforme as políticas. Senha deve conter ao menos um caractere MAIÚSCULO, um numero e um caractere especial";
          break;
        case "LimitExceededException":
        case "TooManyRequestsException":
        case "TooManyFailedAttemptsException":
          title = "Falha exceção de tentativas";
          message = "Excesso de tentativas";
          break;
        case "UserNotConfirmedException":
          title = "Usuário não confirmado";
          message = "O usuário não confirmado";
          break;
        default:
          title = "Contate o suporte";
          message =
            "Desculpe o transtorno. Entre em contato com o nosso suporte para mais informações.";
          break;
      }
      AlertOS({
        title,
        message,
        toastType: "error",
      });
    }
  }

  function handleForgotPasswordVerifyCancel() {
    navigation.navigate("ForgotPassword");
  }

  return (
    <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
      <Container>
        <LogoContainer>
          <Logo source={APPLOGO} />
        </LogoContainer>
        <AppMessage>
          <Title>Código de Verificação</Title>
          <Message>
            Email com código de verificação foi enviado para {email}
          </Message>
        </AppMessage>
        <Formik
          initialValues={initialValues}
          validationSchema={ForgotPasswordVerifyValidationSchema}
          validateOnChange={false}
          onSubmit={(values, formikHelpers) =>
            handleForgotPasswordVerifyRegister(values, formikHelpers)
          }
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <LoginInfo>
              <Input
                leftIcon={
                  <MaterialIcons name="lock" size={24} color={ActionColor} />
                }
                leftIconContainerStyle={{
                  marginLeft: 0,
                  marginRight: 10,
                }}
                label="Código de verificação"
                keyboardType="numeric"
                maxLength={6}
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                ref={inputCodeRef}
                blurOnSubmit={false}
                onSubmitEditing={() => handleSubmit()}
                errorMessage={errors.code}
                value={values.code}
                onChangeText={handleChange("code")}
                onBlur={handleBlur("code")}
                containerStyle={{
                  paddingBottom: dinamicPadding(errors.code, touched.code),
                }}
              />

              <Input
                leftIcon={
                  <MaterialIcons name="lock" size={24} color={ActionColor} />
                }
                leftIconContainerStyle={{
                  marginLeft: 0,
                  marginRight: 10,
                }}
                label="Senha"
                placeholder="******"
                secureTextEntry={true}
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                ref={inputPasswordRef}
                blurOnSubmit={false}
                errorMessage={errors.password}
                value={values.password}
                onChangeText={handleChange("password")}
                onBlur={handleBlur("password")}
                containerStyle={{
                  paddingBottom: dinamicPadding(
                    errors.password,
                    touched.password
                  ),
                }}
              />

              <Button
                title="Confirmar"
                loading={loading}
                onPress={() => handleSubmit()}
                style={{
                  flex: 1,
                }}
              />

              <Link
                text="Voltar"
                onPress={() => handleForgotPasswordVerifyCancel()}
              />
            </LoginInfo>
          )}
        </Formik>
      </Container>
    </KeyboardAwareScrollView>
  );
};

export default ForgotPasswordVerify;
