import { Alert, Platform } from "react-native";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
//TODO: HOWTO import "react-toastify/dist/ReactToastify.css"; esse tipo de import só funciona no browser e quebra a app no android e ios

interface AlertOSProps {
  title: string;
  message: string;
  toastType?: "info" | "success" | "warn" | "error";
}

export function AlertOS({
  title,
  message,
  toastType = "success",
}: AlertOSProps) {
  if (Platform.OS == "web") {
    //TODO: HOWTO forma alternativa de import condicional apenas para web
    require("react-toastify/dist/ReactToastify.css");
    toast[toastType](message);
  } else {
    Alert.alert(title, message);
  }
}
