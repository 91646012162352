import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import React from "react";
import { Button } from "react-native-elements";
import { useAuth } from "../../contexts/auth";
import { Item } from "../../models/product";
import { SuccessColor } from "../../utils/colors";
import { formatCurrency } from "../../utils/formatNumber";
import {
  AppInfo,
  BasketInfo,
  Container,
  InfoContent,
  Message,
  ProductInfo,
  ProductItem,
  ProductList,
  ProductTotal,
  ProductsTitle,
  PurchaseTotal,
  Title,
} from "./styles";

const PaymentConfirmed: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const { isAuthScan, signOut } = useAuth();
  const { itens, total, device_failed } = route.params;
  const navigation = useNavigation();

  return (
    <Container>
      <InfoContent>
        <AppInfo>
          <MaterialIcons
            name="check-circle-outline"
            size={128}
            color={SuccessColor}
          />
        </AppInfo>

        <Title>Pagamento Realizado!</Title>
        <Message>
          Obrigado! Seu pagamento foi efetuado com sucesso. Agora você pode
          abrir a máquina e retirar seus produtos.
        </Message>
      </InfoContent>

      <BasketInfo>
        <ProductsTitle>Resumo da compra</ProductsTitle>
        <ProductList>
          {itens.map((item: Item) => (
            <ProductItem key={item.product.id}>
              <ProductInfo>
                {item.product.name} x{item.quantity}
              </ProductInfo>
              <ProductTotal>
                {" "}
                {formatCurrency((item.quantity * item.product.price) / 100)}
              </ProductTotal>
            </ProductItem>
          ))}
        </ProductList>
        <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
      </BasketInfo>

      {device_failed && (
        <>
          <Message>
            Algum problema com a máquina? Entre em contato com o nosso suporte
          </Message>
          <Button
            type={"clear"}
            onPress={() => {
              navigation.navigate("ChatSupport");
            }}
            title="Conversar com suporte"
          />
        </>
      )}

      {isAuthScan ? (
        <Button
          onPress={() => {
            signOut();
          }}
          title="Finalizar"
        />
      ) : (
        <Button
          onPress={() => {
            navigation.navigate("Home");
          }}
          title="Voltar para o Início"
        />
      )}
    </Container>
  );
};

export default PaymentConfirmed;
