import { MaterialIcons } from "@expo/vector-icons";
import React from "react";
import { IMGMACHINE, LOCATION_IMAGE } from "../../image";
import {
  Categories,
  Container,
  Distance,
  Item,
  ItemDescription,
  ItemImage,
  ItemInfo,
  ItemTitle,
  MachineImage,
  MachineInfo,
  MachineList,
  MachineSelected,
  MachineTitle,
  Star,
  TextInfo,
} from "./styles";

import { useNavigation } from "@react-navigation/native";
import {
  EnumStatusMachine,
  MachineIdentification,
  MachineProps,
} from "../../models/machine";

import { formatDistance } from "../../utils/formatNumber";
import Warning from "../Warning";

export default function Machines({ title, display, machines }: MachineProps) {
  return (
    <Container>
      {machines.length >= 1 ? (
        MachineListView(machines)
      ) : (
        <Warning
          imageSource={LOCATION_IMAGE}
          title="Máquinas não encontradas"
          message="Infelizmente não encontramos máquinas próximas a região em que você está."
        ></Warning>
      )}
    </Container>
  );
}

export function MachineListView(machines: MachineIdentification[]) {
  const navigation = useNavigation();
  return (
    <MachineList>
      {machines?.map((machine) =>
        MachineView(machine, () =>
          navigation.navigate("MachineBasket", {
            machineId: machine.id,
            machine,
          })
        )
      )}
    </MachineList>
  );
}

export function MachineView(machine: MachineIdentification, onPress: any) {
  return (
    <Item
      active={machine.products.length >= 1 ? EnumStatusMachine.ACTIVE : ".4"}
      key={machine.id}
      onPress={
        machine.status != EnumStatusMachine.ACTIVE ||
        machine.products.length <= 0
          ? (e: Event) => {
              e.stopPropagation();
            }
          : (machine: MachineIdentification) => onPress(machine)
      }
    >
      <ItemImage
        active={machine.status != EnumStatusMachine.ACTIVE ? ".3" : 1}
        source={machine.logo ? { uri: machine.logo } : IMGMACHINE}
      />
      <ItemInfo active={machine.status != EnumStatusMachine.ACTIVE ? ".3" : 1}>
        <ItemDescription>
          <ItemTitle>{machine.name}</ItemTitle>
        </ItemDescription>
        <ItemDescription>
          <MaterialIcons name="star" size={20} color="#FF7C01" />
          <Star>{"Nova!"} </Star>
          <Categories>{machine?.categories?.join(" | ")}</Categories>
          <Distance>
            {machine.distance
              ? formatDistance(machine.distance)
              : "distância desconhecida"}
          </Distance>
        </ItemDescription>
        <ItemDescription>
          <TextInfo>
            {machine?.location?.address}
            {machine?.location?.number ? ", " + machine.location.number : ""}
            {machine?.location?.complement
              ? " - " + machine?.location.complement
              : ""}
          </TextInfo>
        </ItemDescription>
        {/* <Restriction></Restriction> */}
      </ItemInfo>
    </Item>
  );
}

export function MachineSelectedView(
  navigation: any,
  machine: MachineIdentification
) {
  console.log(machine);

  return (
    <MachineSelected onPress={() => navigation.goBack()}>
      <MachineImage
        source={machine?.logo ? { uri: machine?.logo } : IMGMACHINE}
      />
      <MachineInfo>
        <MachineTitle>{machine?.name}</MachineTitle>
        <ItemDescription>
          <MaterialIcons name="star" size={20} color="#FF7C01" />
          <Star>{machine?.star ? machine?.star : "Nova! "} </Star>
          {/* <Categories>{machine?.V_CATEGORIES?.join(' | ')}</Categories> */}
          <Distance>
            {machine?.distance
              ? " a " + formatDistance(machine?.distance)
              : "distância desconhecida"}
          </Distance>
        </ItemDescription>
        <ItemDescription>
          <TextInfo>
            {machine?.location?.address}
            {machine?.location?.number ? ", " + machine?.location?.number : ""}
            {machine?.location?.complement
              ? " - " + machine?.location?.complement
              : ""}
          </TextInfo>
        </ItemDescription>
      </MachineInfo>
    </MachineSelected>
  );
}
